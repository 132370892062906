/**
 * MEMO:
 * `process.env[key]`みたいな書き方するとNext.jsに無視されてundefinedになるため、環境変数一個ずつ処理している
 * 冗長に見えるが、環境変数まわりは頻繁に変更が加えられるところではないため、許容した
 */

type Keys =
  | 'NEXT_PUBLIC_API_ENDPOINT'
  | 'NEXT_PUBLIC_AUTH0_DOMAIN'
  | 'NEXT_PUBLIC_AUTH0_CLIENT_ID'
  | 'NEXT_PUBLIC_AUTH0_AUDIENCE'
  | 'NEXT_PUBLIC_SENTRY_DSN'
  | 'NEXT_PUBLIC_APP_ENV'

type GetEnv = () => { [key in Keys]: string }
export const getEnv: GetEnv = () => ({
  NEXT_PUBLIC_API_ENDPOINT: unwrap(
    process.env.NEXT_PUBLIC_API_ENDPOINT,
    'NEXT_PUBLIC_API_ENDPOINT',
  ),
  NEXT_PUBLIC_AUTH0_DOMAIN: unwrap(
    process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    'NEXT_PUBLIC_AUTH0_DOMAIN',
  ),
  NEXT_PUBLIC_AUTH0_CLIENT_ID: unwrap(
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    'NEXT_PUBLIC_AUTH0_CLIENT_ID',
  ),
  NEXT_PUBLIC_AUTH0_AUDIENCE: unwrap(
    process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
    'NEXT_PUBLIC_AUTH0_AUDIENCE',
  ),
  NEXT_PUBLIC_SENTRY_DSN: unwrap(process.env.NEXT_PUBLIC_SENTRY_DSN, 'NEXT_PUBLIC_SENTRY_DSN'),
  NEXT_PUBLIC_APP_ENV: unwrap(process.env.NEXT_PUBLIC_APP_ENV, 'NEXT_PUBLIC_APP_ENV'),
})

type Unwrap = (value: string | undefined, key: Keys) => string
const unwrap: Unwrap = (value, key) => {
  if (!value) {
    throw new Error(`環境変数"${key}"が設定されていません`)
  }

  return value
}
