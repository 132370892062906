var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fbh6Jjx1RCineduoPuTBZ"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

import { getEnv } from '@/utils/get-env'

Sentry.init({
  dsn: getEnv().NEXT_PUBLIC_SENTRY_DSN,
  environment: getEnv().NEXT_PUBLIC_APP_ENV,
  tracesSampleRate: 1.0,
})
